import {createContext, useContext} from "react"

export const TypesenseApiKeyContext = createContext<string | null>(null)

export const useTypesenseApiKey = () => {
  const context = useContext(TypesenseApiKeyContext)

  if (!context) throw new Error("Can not get Typesense Api Key")

  return context
}
