import {createContext, useContext} from "react"

export type UserStores = Array<{id: string; name: string}>

export const UserStoresContext = createContext<UserStores | null>(null)

export const useUserStores = () => {
  const ctx = useContext(UserStoresContext)
  if (!ctx) throw new Error("Missing UserStoresContext.Provider")

  return ctx
}
