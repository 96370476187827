import "client-only"
import {connectAuthEmulator, getAuth} from "firebase/auth"
import {getApps, initializeApp} from "firebase/app"
import {
  authEmulatorHost,
  firebaseConfig,
  firestoreEmulatorHost,
  functionsEmulatorHost,
  storageEmulatorHost,
} from "../config/client"
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore"
import {connectStorageEmulator, getStorage} from "firebase/storage"
import {connectFunctionsEmulator, getFunctions} from "firebase/functions"
import {toNumber} from "lodash"

const existingApp = getApps().find((app) => app.name === "[DEFAULT]")

export const app = existingApp ?? initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const firestore = getFirestore(app)

export const storage = getStorage(app)
export const functions = getFunctions(app, "europe-west1")

if (firestoreEmulatorHost && !existingApp) {
  const [host, port] = firestoreEmulatorHost.split(":")
  connectFirestoreEmulator(firestore, host, toNumber(port))
}

if (authEmulatorHost && !existingApp) {
  connectAuthEmulator(auth, authEmulatorHost)
}

if (functionsEmulatorHost && !existingApp) {
  const [host, port] = functionsEmulatorHost.split(":")
  connectFunctionsEmulator(functions, host, toNumber(port))
}

if (storageEmulatorHost && !existingApp) {
  const [host, port] = storageEmulatorHost.split(":")
  connectStorageEmulator(storage, host, toNumber(port))
}
