import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/(main)/UserStoresClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/components/CanteenSelectBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/components/MenuItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/components/Openable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/app/TypesenseApiKeyClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/LogoutButton.tsx");
