"use client"

import {auth} from "../firebase"
import {useRouter} from "next/navigation"

const LogoutButton = ({title}: {title: string}) => {
  const router = useRouter()

  return (
    <div className="px-1.5 py-0.5">
      <button
        onClick={async () => {
          await auth.signOut()
          await fetch("/api/logout")
          router.replace("/login")
        }}
        className="flex items-center gap-2 rounded-md p-2.5"
      >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="log-out-01">
            <path
              id="Icon"
              d="M10.6667 11.3333L14 8M14 8L10.6667 4.66667M14 8H6M6 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.7157 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.07989 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.0799 14 5.2 14H6"
              stroke="#F97066"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
        <span className="text-sm font-medium text-error-400">{title}</span>
      </button>
    </div>
  )
}
export default LogoutButton
