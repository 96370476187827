import {DetailedHTMLProps, HTMLAttributes, forwardRef} from "react"

type OptionProps = DetailedHTMLProps<HTMLAttributes<HTMLLIElement>, HTMLLIElement>

const Option = forwardRef<HTMLLIElement, OptionProps>(({children, ...rest}, ref) => {
  return (
    <li
      ref={ref}
      {...rest}
      className="group flex shrink-0 cursor-default items-center justify-between gap-2 rounded-md p-2.5 pl-2 data-[focus]:bg-gray-50"
    >
      {children}
      <svg
        className={"invisible group-data-[selected]:visible"}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6668 5L7.50016 14.1667L3.3335 10"
          className="stroke-brand"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </li>
  )
})

Option.displayName = "Option"

export default Option
