"use client"
import {usePathname, useRouter} from "next/navigation"
import {useCallback, useEffect, useRef, useState} from "react"
import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions} from "@headlessui/react"
import Option from "@/components/forms/Option"
import Options from "@/components/forms/Options"
import {twJoin, twMerge} from "tailwind-merge"
import StyledInput, {input} from "@/components/forms/StyledInput"
import {z} from "zod"
import {useRouteParams} from "next-typesafe-url/app"
import {FaUsersViewfinder} from "react-icons/fa6"

type Option = {id: string; name: string; rootHref: string}

type CanteenSelectBar = {
  placeholder: string
  options: Array<Option>
}

const schema = z.object({
  storeId: z
    .string()
    .transform(decodeURIComponent)
    .transform((a) => a.replaceAll(" ", "+"))
    .optional(),
  id: z.any().optional(),
})

const CanteenSelectBar = ({placeholder, options}: CanteenSelectBar) => {
  const router = useRouter()

  const pathname = usePathname()

  const params = useRouteParams(schema)

  const [query, setQuery] = useState("")

  const getNewHref = useCallback(
    ({id, rootHref}: Option) => {
      if (params.isError || params.isLoading) return

      const {storeId, id: detailId} = params.data

      if (storeId) {
        if (detailId) {
          const split = pathname.split("/")
          const index = split.indexOf(detailId)
          return split.slice(0, index).join("/").replace(storeId, id)
        }

        return pathname.replace(storeId, id)
      }

      return rootHref
    },
    [pathname, params],
  )

  useEffect(() => {
    options.forEach((store) => {
      const r = getNewHref(store)
      if (r) router.prefetch(r)
    })
  }, [options, router, getNewHref])

  if (params.isError || params.isLoading) return null

  const {storeId} = params.data

  const selectedCanteen = options.find((c) => c.id === storeId) ?? null

  const filteredOptions = options.filter((o) => o.name.toLowerCase().includes(query.toLowerCase()))

  return (
    <Combobox
      immediate
      value={selectedCanteen}
      onClose={() => setQuery("")}
      onChange={(store) => {
        if (store) {
          if (store.id === selectedCanteen?.id) return
          const r = getNewHref(store)
          if (r) router.push(r)
        }
      }}
    >
      <div className="relative">
        <ComboboxInput
          autoComplete="off"
          className={twMerge(
            input(),
            "w-full pr-12",
            selectedCanteen ? "placeholder:text-gray-900" : "placeholder:text-gray-500",
          )}
          displayValue={() => ""}
          placeholder={selectedCanteen?.name ?? placeholder}
          onChange={(e) => setQuery(e.target.value)}
        />
        <ComboboxButton className="group absolute inset-y-0 right-0 flex items-center px-4">
          <svg
            className="size-4 fill-gray-600 group-hover:fill-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
          >
            <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
          </svg>
        </ComboboxButton>
      </div>
      <ComboboxOptions className="w-[var(--input-width)]" as={Options} anchor={{to: "bottom", gap: "8px"}}>
        {filteredOptions.map((store) => (
          <ComboboxOption as={Option} value={store} key={store.id}>
            {store.name}
          </ComboboxOption>
        ))}
      </ComboboxOptions>
    </Combobox>
  )
}

export default CanteenSelectBar
